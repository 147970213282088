import React from "react";
import "../style/skill.css";
import html from "../asserts/icon/html.webp";
import css from "../asserts/icon/css.webp";
import Javascript from "../asserts/icon/Javascript.webp";
import python from "../asserts/icon/python.webp";
import SQL from "../asserts/icon/sql.webp";
import git from "../asserts/icon/git.webp";
import react from "../logo.svg";
import { Helmet } from "react-helmet";
export default function Skill() {
  return (
    <div className="background1" data-aos="fade-up">
      <Helmet>
        <meta name="title" content="Ayush | Skill" />

        <meta property="og:title" content="Ayush | Skill"/>


        <meta charset="UTF-8" />

        <meta http-equiv="X-UA-Compatible" content="IE=chrome" />

        <meta name="viewport" content="width=device-width,initial-scale=1" />

        <meta name="robots" content="index, follow" />

        <meta name="url" content="https://www.ayush-portfolio.rf.gd/Skill " />

        <meta
          name="identifier-url"
          content="https://www.ayush-portfolio.rf.gd/Skill "
        />

        <meta name="revisit-after" content="1 days" />

        <meta name="googlebot" content="index, follow" />

        <link
          rel="canonical"
          href="https://www.ayush-portfolio.rf.gd/Skill"
        />

        <meta name="bingbot" content="index, follow" />

        
      </Helmet>
      <div className="top">
        <h1>Technical Skills</h1>
      </div>

      <div className="skills-wrapper">
        <div className="skills-item">
          <div>
            <img src={html} alt="html" className="skill-img"></img>
          </div>
          <h3>HTML</h3>
        </div>
        <div className="skills-item">
          <div>
            <img src={css} alt="css" className="skill-img"></img>
          </div>
          <h3>CSS</h3>
        </div>
        <div className="skills-item">
          <div>
            <img src={Javascript} alt="Javascript" className="skill-img"></img>
          </div>
          <h3>Javascript</h3>
        </div>
        <div className="skills-item">
          <div>
            <img src={react} alt="react" className="skill-img"></img>
          </div>
          <h3>Reactjs</h3>
        </div>
        <div className="skills-item">
          <div>
            <img src={python} alt="python" className="skill-img"></img>
          </div>
          <h3>python</h3>
        </div>
        <div className="skills-item">
          <div>
            <img src={SQL} alt="SQL" className="skill-img"></img>
          </div>
          <h3>SQL</h3>
        </div>
      </div>
      <div className="top">
        <h1>Other Skills</h1>
      </div>

      <div className="skills-wrapper">
        <div className="skills-item">
          <div>
            <img src={git} alt="git" className="skill-img"></img>
          </div>
          <h3>GIt</h3>
        </div>
      </div>
    </div>
  );
}
